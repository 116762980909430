<template>
  <VAutocomplete
    v-model="contentIds"
    label="Контент"
    :items="contentItems"
    :item-text="'title'"
    :item-value="'id'"
    :loading="loading"
    :filter="filterContentItems"
    :search-input.sync="query"
    :rules="rules"
    multiple
    chips
    deletable-chips
    large
  >
    <template #item="{ item }">
      <VListItemContent>
        <div class="d-flex flex-row">
          <VCheckbox
            :value="contentIds.includes(item.id)"
            :ripple="false"
            class="pa-0 ma-0"
          />

          <div>
            <VListItemTitle v-text="item.title" />
            <VListItemSubtitle v-text="item.id" />
          </div>
        </div>
      </VListItemContent>
    </template>
  </VAutocomplete>
</template>

<script>
export default {
  name: 'ContentPicker',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    contentItems: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contentIds: [],
      query: '',
    };
  },
  watch: {
    value() {
      this.contentIds = this.value;
    },
    contentIds(v) {
      this.$emit('input', v);
    },
  },
  methods: {
    filterContentItems(item, query) {
      const q = query.trim().toLowerCase();
      return item.title.toLowerCase().indexOf(q) !== -1 || item.id.indexOf(q) !== -1;
    },
  },
};
</script>

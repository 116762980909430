<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VContainer fluid>
            <VRow>
              <VCol>
                <h1>Топики</h1>
              </VCol>
            </VRow>
            <VRow>
              <VCol md="3">
                <h3>Добавить топик</h3>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <VForm
                  ref="form"
                  v-model="isValid"
                  lazy-validation
                >
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        v-model="topic.name"
                        label="Название"
                        :rules="[rules.required]"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <TTTextField
                        v-model="topic.key"
                        label="Ключ топика"
                        :rules="[rules.required]"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <TTTextarea
                        v-model="topic.description"
                        label="Описание"
                        :rules="[rules.required]"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <ContentPicker
                        v-model="topic.contentIds"
                        :content-items="contentItems.values"
                        :loading="contentItems.loading"
                        :rules="[rules.required]"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol cols="12">
                      <TTBtn
                        :disabled="!isValid"
                        @click="$refs.form.validate() && createTopic()"
                      >
                        Создать
                      </TTBtn>
                    </VCol>
                  </VRow>
                </VForm>
              </VCol>
            </VRow>
          </VContainer>
        </VCard>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <TTDataTable
          :headers="headers"
          :items="topics.values"
          :loading="topics.loading"
          disable-pagination
          disable-sort
        >
          <template #item.id="{ item }">
            <span class="word-break-all">
              {{ item.id }}
            </span>
          </template>

          <template #item.name="{ item }">
            <span>
              {{ item.name }}
            </span>
          </template>

          <template #item.key="{ item }">
            <span class="word-break-all">
              {{ item.key }}
            </span>
          </template>

          <template #item.actions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <TTBtn
                  icon
                  fab
                  color="tt-light-yellow"
                  v-bind="attrs"
                  :to="{ name : Names.R_LEARNING_COURSE_V2_TOPICS_EDIT, params : { topicId : item.id} }"
                  v-on="on"
                >
                  <VIcon>
                    $edit
                  </VIcon>
                </TTBtn>
              </template>
              <span>Редактировать</span>
            </VTooltip>
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <TTBtn
                  icon
                  fab
                  color="tt-red"
                  v-bind="attrs"
                  v-on="on"
                  @click="openArchiveDialog(item)"
                >
                  <VIcon>
                    $archive
                  </VIcon>
                </TTBtn>
              </template>
              <span>Архивировать</span>
            </VTooltip>
          </template>
        </TTDataTable>
        <VPagination
          v-if="topicsPagesTotal > 0"
          v-model="topics.page"
          total-visible="10"
          :length="topicsPagesTotal"
        />
      </VCol>
    </VRow>
    <VDialog
      v-model="topicArchiveDialog"
      max-width="500"
    >
      <VCard>
        <VCardTitle class="headline">
          Архивировать топик
        </VCardTitle>
        <VCardText>
          Этот топик будет архивирован! <br>
          Действительно хотите архивировать топик?
        </VCardText>
        <VCardActions>
          <VSpacer />
          <VBtn
            text
            @click="closeArchiveDialog"
          >
            Нет
          </VBtn>

          <VBtn
            color="red darken-1"
            text
            @click="archiveTopic(topicToArchive)"
          >
            Да
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
  </TTView>
</template>

<script>
import { required } from '@front.backoffice/common';
import ContentPicker from '../../components/learning/ContentPicker.vue';

export default {
  name: 'CreateTopic',
  components: {
    ContentPicker,
  },
  inject: ['Names'],
  data() {
    return {
      topic: {
        name: '',
        description: '',
        key: '',
        contentIds: [],
      },
      isValid: false,
      topics: {
        values: [],
        total: 0,
        page: 1,
        pageSize: 20,
        loading: false,
      },
      contentItems: {
        values: [],
        query: '',
        loading: false,
      },
      headers: [
        { text: 'Id', value: 'id', width: '25%' },
        { text: 'Название', value: 'name', width: '30%' },
        { text: 'Ключ', value: 'key', width: '35%' },
        { text: 'Действия', value: 'actions', width: '10%' },
      ],
      topicArchiveDialog: false,
      topicToArchive: {
        id: null,
      },
    };
  },
  computed: {
    topicsPagesTotal() {
      return Math.ceil(this.topics.total / this.topics.pageSize);
    },
    rules() {
      return {
        required: required(),
      };
    },
  },
  watch: {
    'topics.page': {
      async handler(pageNumber) {
        const { topics = [], pagination: { total = 0 } } = await this.fetchTopicsPage(pageNumber, this.topics.pageSize);
        this.topics.values = topics;
        this.topics.total = total;
      },
      immediate: true,
    },
  },
  async created() {
    this.contentItems.values = await this.fetchContentItemsByText();
  },
  methods: {
    async createTopic() {
      try {
        await this.$di.api.TtCourse.createTopic({ topic: this.topic });
        this.$refs.form.reset();
        this.$di.notify.snackSuccess('Топик создан');
        const { topics = [] } = await this.fetchTopicsPage(this.topics.page);
        this.topics.values = topics;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    async archiveTopic({ id }) {
      try {
        await this.$di.api.TtCourse.archiveTopic({ id });
        this.topicArchiveDialog = false;
        this.$di.notify.snackSuccess('Топик архивирован');
        const { topics = [] } = await this.fetchTopicsPage(this.topics.page);
        this.topics.values = topics;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    openArchiveDialog({ id }) {
      this.topicToArchive.id = id;
      this.topicArchiveDialog = true;
    },
    closeArchiveDialog() {
      this.topicToArchive.id = null;
      this.topicArchiveDialog = false;
    },
    async fetchTopicsPage(page = 1, limit = this.topics.pageSize) {
      try {
        this.topics.loading = true;
        return await this.$di.api.TtCourse.getTopics({ page, limit });
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return null;
      } finally {
        this.topics.loading = false;
      }
    },
    // Ручка возвращает все записи, независимо от аргумента. Потом сделают чтобы искала.
    async fetchContentItemsByText(textForSearch = '') {
      try {
        this.contentItems.loading = true;
        const { contentItems = [] } = await this.$di.api.TtCourse.searchContentItems({ textForSearch });
        return contentItems;
      } catch (e) {
        this.$di.notify.errorHandler(e);
        return [];
      } finally {
        this.contentItems.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.word-break-all {
  word-break: break-all;
}
</style>
